/*
 |-----------------------------------------------------------------------------
 | pages/_app.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { useEffect } from 'react';

import * as Alerty from '@alerty/nextjs';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Provider } from 'react-redux';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

// import Loader from '@/molecules/Loader';
import { pageView } from '@/utils/helpers';
import { store } from '@/app/store';

import 'styles/global.css';

const Script = dynamic(() => import('next/script'));
const Search = dynamic(() => import('@/organisms/Search'));

if (process.env.NODE_ENV === 'production') {
	Alerty.configure({
		dsn: process.env.NEXT_PUBLIC_ALERTY_DSN as string,
	});
}

const MyApp = ({ Component, pageProps }: AppProps) => {
	const router = useRouter();

	useEffect(() => {
		if (process.env.NEXT_PUBLIC_ENV === 'production') {
			router.events.on('routeChangeComplete', pageView);

			return () => {
				router.events.off('routeChangeComplete', pageView);
			};
		}
	}, [router.events]);

	return (
		<>
			<Head>
				<meta charSet="UTF-8" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, shrink-to-fit=no"
				/>

				<link rel="preconnect" href="https://res.cloudinary.com" />
				<link rel="dns-prefetch" href="https://res.cloudinary.com" />

				{process.env.NEXT_PUBLIC_SNIPCART_API_KEY && (
					<>
						<link
							rel="preconnect"
							href="<https://app.snipcart.com>"
						/>
						<link
							rel="preconnect"
							href="<https://cdn.snipcart.com>"
						/>
						<link
							rel="stylesheet"
							href="<https://cdn.snipcart.com/themes/v3.2.0/default/snipcart.css>"
						/>
					</>
				)}

				<link rel="icon" type="image/svg+xml" href="/favicon.svg" />
				<link
					rel="icon"
					type="image/png"
					sizes="32x32"
					href="/favicon-32x32.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="16x16"
					href="/favicon-16x16.png"
				/>

				<link
					rel="icon"
					type="image/png"
					sizes="48x48"
					href="/favicon-48x48.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="192x192"
					href="/favicon-192x192.png"
				/>

				<link
					rel="apple-touch-icon"
					type="image/png"
					sizes="167x167"
					href="/favicon-167x167.png"
				/>
				<link
					rel="apple-touch-icon"
					type="image/png"
					sizes="180x180"
					href="/favicon-180x180.png"
				/>

				<link rel="author" href="/humans.txt" />
			</Head>
			{process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID && (
				<>
					<Script id="gcm" strategy="afterInteractive">
						{`
							window.dataLayer = window.dataLayer || [];

							function gtag() {
								dataLayer.push(arguments)
							}

							gtag("consent", "default", {
								ad_storage: "denied",
								analytics_storage: "denied",
								functionality_storage: "denied",
								personalization_storage: "denied",
								security_storage: "granted",
								wait_for_update: 500
							});

							gtag("set", "ads_data_redaction", true);
							gtag("set", "url_passthrough", true);
						`}
					</Script>
					<Script id="gtm" strategy="afterInteractive">
						{`
							(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
							new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
							j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
							'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
							})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');
						`}
					</Script>
				</>
			)}
			{process.env.NEXT_PUBLIC_ENV !== 'development' && (
				<Script
					src="https://cdn-cookieyes.com/client_data/ef87310835787a1994744874/script.js"
					strategy="beforeInteractive"
				/>
			)}
			{process.env.NEXT_PUBLIC_SNIPCART_API_KEY && (
				<Script
					src="https://cdn.snipcart.com/themes/v3.2.0/default/snipcart.js"
					strategy="afterInteractive"
				/>
			)}
			<Provider store={store}>
				{/* <Loader /> */}
				<Component {...pageProps} />
				<Search
					index={process.env.NEXT_PUBLIC_ALGOLIA_INDEX}
					placeholder="Search site"
				/>
			</Provider>
		</>
	);
};

export default MyApp;
