var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"c53d40c2c24548b92a02e7c3bdc6ba73c7d35393"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/*
 |-----------------------------------------------------------------------------
 | sentry.client.config.js
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | This file configures the initialization of Sentry on the browser. The config
 | you add here will be used whenever a page is visited.
 |
 */

import * as Sentry from '@sentry/nextjs';
import LogRocket from 'logrocket';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NEXT_PUBLIC_ENV !== 'development') {
	LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_APP_ID);

	Sentry.init({
		dsn:
			SENTRY_DSN ||
			'https://96db53121f704d9fb2574f4cb0f2be4a@o1039980.ingest.sentry.io/6564337',
		environment: process.env.NEXT_PUBLIC_ENV,
		replaysOnErrorSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		tracesSampleRate: 1.0,

		integrations: [new Sentry.Replay()],

		beforeSend(e) {
			const logRocketSession = LogRocket.sessionURL;

			if (logRocketSession !== null) {
				e.extra['LogRocket'] = logRocketSession;
				return e;
			} else {
				return e;
			}
		},
	});
} else {
	Sentry.init({
		dsn:
			SENTRY_DSN ||
			'https://96db53121f704d9fb2574f4cb0f2be4a@o1039980.ingest.sentry.io/6564337',
		environment: process.env.NEXT_PUBLIC_ENV,
		replaysOnErrorSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		tracesSampleRate: 1.0,

		integrations: [new Sentry.Replay()],
	});
}
